<template>
	<v-container class="py-0" fill-height fluid>
		<v-row justify="center">
			<v-col align="center">
				<v-img
					:src="require('@/assets/images/online-meeting.png')"
					width="250"
				></v-img>
				<v-card-text class="mt-4">
					<AppButton
						label="app.create_meeting"
						color="info"
						icon="plus"
						:min_width="180"
						:outlined="true"
						:class="['font-weight-bold']"
						@click="
							(selected_action = $defines.CREATE_MEETING),
								(meeting_dialog = true)
						"
					></AppButton>

					<AppButton
						label="app.join_meeting"
						color="info"
						icon="group"
						:min_width="180"
						:class="['ms-2']"
						@click="
							(selected_action = $defines.JOIN_MEETING), (meeting_dialog = true)
						"
					></AppButton>
				</v-card-text>
			</v-col>
		</v-row>

		<MeetingDialog
			v-model="meeting_dialog"
			:dialog="meeting_dialog"
			:selected_action="selected_action"
			@cancel="(meeting_dialog = false), (selected_action = null)"
		>
		</MeetingDialog>
	</v-container>
</template>

<script>
import MeetingDialog from "@/components/shared/tools/meeting/MeetingDialog.vue";

export default {
	name: "ToolsMeeting",

	data() {
		return {
			meeting_dialog: false,
			selected_action: null,
		};
	},

	components: {
		MeetingDialog,
	},
};
</script>
