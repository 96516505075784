<template>
	<v-dialog :value="dialog" width="650" persistent>
		<AppDialog
			v-model="loading"
			:heading="{
				label:
					selected_action === $defines.CREATE_MEETING
						? $t('app.create_meeting')
						: $t('app.join_meeting'),
			}"
			:action="{
				label:
					selected_action === $defines.CREATE_MEETING
						? $t('app.start_call')
						: $t('app.join_call'),
				event: 'initiate-call',
			}"
			@close="$emit('cancel'), reset()"
			@initiate-call="initiateCall()"
		>
			<template v-slot:dialog-content>
				<!-- when user requested to create a new call -->
				<div v-if="selected_action === $defines.CREATE_MEETING">
					<div align="center">
						<v-icon size="60" color="amberDarken1">
							$vuetify.icons.values.check_circle
						</v-icon>
					</div>

					<div
						class="black--text subtitle-1 font-weight-bold mt-3"
						align="center"
					>
						{{ $t("app.meeting_created_successfully") }}
					</div>

					<v-row no-gutters class="mt-7">
						<v-col class="text-truncate">
							<v-card height="55" flat outlined tile class="copy-text-card-wr">
								<v-card-text class="py-3 black--text subtitle-1">
									{{ channel }}
								</v-card-text>
							</v-card>
						</v-col>
						<v-col sm="4">
							<CopyToClipboard
								:message="channel"
								:use_icon="false"
							></CopyToClipboard>
						</v-col>
					</v-row>

					<div
						class="subtitle-1 mt-5"
						v-html="$t('app.share_channel_with_contacts')"
					></div>
				</div>

				<!-- User requested to join a call -->
				<div v-if="selected_action === $defines.JOIN_MEETING">
					<div align="center">
						<v-icon size="60" color="amberDarken1">
							$vuetify.icons.values.phone
						</v-icon>
					</div>

					<v-form ref="form" class="mt-7">
						<v-text-field
							hide-details
							v-model.trim="channel"
							:rules="[rules.required]"
							color="greenAccent2"
							background-color="white"
							outlined
							:label="$t('app.channel_name')"
						>
						</v-text-field>
					</v-form>

					<div class="subtitle-1 mt-5" v-html="$t('app.about_to_join')"></div>
				</div>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import rules from "@/utils/validation";
import CopyToClipboard from "@/components/plugins/CopyToClipboard";
import AppDialog from "@/components/ui/AppDialog.vue";

export default {
	name: "MeetingDialog",

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},
		selected_action: {
			required: true,
		},
	},

	data() {
		return {
			rules,
			loading: false,
			channel: null,
		};
	},

	components: {
		CopyToClipboard,
		AppDialog,
	},

	watch: {
		dialog(newVal, oldVal) {
			if (newVal) {
				if (this.selected_action === this.$defines.CREATE_MEETING) {
					this.setUpNewCall();
				}
				if (this.selected_action === this.$defines.JOIN_MEETING) {
					this.setUpJoinCall();
				}
			}
		},
	},

	methods: {
		reset() {
			this.channel = null;
			if (this.$refs.form) {
				this.$refs.form.reset();
			}
		},

		async setUpNewCall() {
			try {
				this.$loader.start();

				// Create a new channel to initiate the call
				let res = await this.$store.dispatch("agora/generateToken");
				this.channel = res.channelName;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async setUpJoinCall() {
			try {
				this.$loader.start();
				this.channel = null;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async isChannelValid() {
			let response = await this.$store.dispatch("agora/retrieveToken", {
				channel_name: this.channel,
			});

			// If response is not having token that means input channel is invalid.
			if (!response.Token) {
				throw new Error("app.ntfy.err.invalid_input_channel", {
					cause: "werCustom",
				});
			}
		},

		async initiateCall() {
			try {
				this.loading = true;

				if (this.selected_action === this.$defines.JOIN_MEETING) {
					// If channel is not inputed
					if (!this.$refs.form.validate()) return;
					// If input channel is wrong
					await this.isChannelValid();
				}

				// Push to calling route in a new window
				let routeData = this.$router.resolve({
					name: "AgoraIndexPage",
					params: {
						channel_name: this.channel,
						dummy_window: false,
					},
				});
				window.open(routeData.href, "_blank");

				// reset and close the dialog in current window
				this.$emit("cancel");
				this.reset();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
