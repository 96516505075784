var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialog,"width":"650","persistent":""}},[_c('AppDialog',{attrs:{"heading":{
			label:
				_vm.selected_action === _vm.$defines.CREATE_MEETING
					? _vm.$t('app.create_meeting')
					: _vm.$t('app.join_meeting'),
		},"action":{
			label:
				_vm.selected_action === _vm.$defines.CREATE_MEETING
					? _vm.$t('app.start_call')
					: _vm.$t('app.join_call'),
			event: 'initiate-call',
		}},on:{"close":function($event){_vm.$emit('cancel'), _vm.reset()},"initiate-call":function($event){return _vm.initiateCall()}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [(_vm.selected_action === _vm.$defines.CREATE_MEETING)?_c('div',[_c('div',{attrs:{"align":"center"}},[_c('v-icon',{attrs:{"size":"60","color":"amberDarken1"}},[_vm._v(" $vuetify.icons.values.check_circle ")])],1),_c('div',{staticClass:"black--text subtitle-1 font-weight-bold mt-3",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("app.meeting_created_successfully"))+" ")]),_c('v-row',{staticClass:"mt-7",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-truncate"},[_c('v-card',{staticClass:"copy-text-card-wr",attrs:{"height":"55","flat":"","outlined":"","tile":""}},[_c('v-card-text',{staticClass:"py-3 black--text subtitle-1"},[_vm._v(" "+_vm._s(_vm.channel)+" ")])],1)],1),_c('v-col',{attrs:{"sm":"4"}},[_c('CopyToClipboard',{attrs:{"message":_vm.channel,"use_icon":false}})],1)],1),_c('div',{staticClass:"subtitle-1 mt-5",domProps:{"innerHTML":_vm._s(_vm.$t('app.share_channel_with_contacts'))}})],1):_vm._e(),(_vm.selected_action === _vm.$defines.JOIN_MEETING)?_c('div',[_c('div',{attrs:{"align":"center"}},[_c('v-icon',{attrs:{"size":"60","color":"amberDarken1"}},[_vm._v(" $vuetify.icons.values.phone ")])],1),_c('v-form',{ref:"form",staticClass:"mt-7"},[_c('v-text-field',{attrs:{"hide-details":"","rules":[_vm.rules.required],"color":"greenAccent2","background-color":"white","outlined":"","label":_vm.$t('app.channel_name')},model:{value:(_vm.channel),callback:function ($$v) {_vm.channel=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"channel"}})],1),_c('div',{staticClass:"subtitle-1 mt-5",domProps:{"innerHTML":_vm._s(_vm.$t('app.about_to_join'))}})],1):_vm._e()]},proxy:true}]),model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }